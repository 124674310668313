




















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissLab6Reflection',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        reflectionPart1: null,
        reflectionPart2: null,
        reflectionPart3: null,
      },
      questions: [
        {
          text: '1) During these past three weeks, what have you learned about characterizing (identifying) an unknown material or substance?',
          inputName: 'reflectionPart1',
        },
        {
          text: '2) During these past three weeks, what have you learned about designing a scientific experiment to answer a question? What are the things that could possibly cause an error in your measurement(s) and therefore your conclusion. ',
          inputName: 'reflectionPart2',
        },
        {
          text: '3) During these past three weeks, what have you learned about disseminating the results of your work to your peers? If your experiment is NOT successful, is THAT worth telling your colleagues about?',
          inputName: 'reflectionPart3',
        },
      ],
    };
  },
};
